@tailwind base;
@tailwind components;
@tailwind utilities;

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-corner ::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(51, 53, 65, 1);
  border-radius: 8px;
}
:root {
  --secondary-color: #c8354f;
  --primary-color: #000000;
  --background-color: ##333541;
}
